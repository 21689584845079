/** @jsx jsx */
import { Link, graphql } from "gatsby"
import { Flex, Box, Heading, Button, Text, jsx } from "theme-ui"

import BestPlace from "../images/undraw-best-place.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Reviews from "../components/reviews"

const IndexPage = ({ data }) => {
  return (
    <Layout fullWidth>
      <SEO title={`S&M Building Management`} />
      <Box
        sx={{
          position: "relative",
          background: `url(${data.coverPhoto.childImageSharp.fixed.src}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            bg: "blue-900",
            opacity: "0.4",
          }}
        />

        <Flex
          sx={{
            flexFlow: "column",
            alignItems: "center",
            borderTop: (theme) => `3px solid ${theme.colors["blue-700"]}`,
            position: "relative",
            textAlign: "center",
          }}
        >
          <Heading
            as="h1"
            sx={{
              color: "blue-100",
              pt: [6],
              pb: [3],
              fontSize: 6,
              fontFamily: "banner",
            }}
          >
            S&M Building Management
          </Heading>
          <Heading as="h2" sx={{ color: "blue-100", pb: [5], px: [1, 0] }}>
            Family owned apartment rentals and commercial spaces
          </Heading>
          <Button
            as={Link}
            variant="blue"
            style={{ hidden: "true" }}
            sx={{ mb: [6, 7], mt: [5, 6], visibility: "hidden" }}
            to="/available-apartments"
          >
            Browse available apartments
          </Button>
        </Flex>
      </Box>
      <Flex
        sx={{
          bg: "blue-200",
          justifyContent: "center",
        }}
      >
        <Flex
          sx={{ flex: "1 1 auto", justifyContent: "center", flexFlow: "wrap" }}
        >
          <Text
            sx={{
              mt: [5],
              mb: [-5, 5],
              px: [3, 0],
              flex: ["0 1 100%", "0 1 50%"],
              fontSize: [4],
            }}
          >
            S&M Building Management provides tenants with a friendly, personal
            experience. Our Chicago apartment rentals come with the modern
            conveniences you expect.
          </Text>
          <BestPlace sx={{ flexShrink: 1 }} />
        </Flex>
      </Flex>
      <Flex sx={{ justifyContent: "center", mb: [5] }}>
        <Flex
          sx={{
            flex: "1 1 auto",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading sx={{ my: [5], textAlign: "center" }}>
            Just ask our happy customers
          </Heading>
          <Flex sx={{ justifyContent: "center", maxWidth: "100%" }}>
            <Box sx={{ maxHeight: "300px", overflow: "hidden" }}>
              <Reviews />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  )
}

export const query = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "cover-photo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
        fixed(width: 2400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default IndexPage
