/** @jsx jsx */
import { useState, useEffect } from "react"
import { jsx, Box, Text } from "theme-ui"

const REVIEWS = [
  {
    text:
      "With so many management companies to choose from, we feel very fortunate to be living in a building managed by S&M Management.",
    author: "Paul & Alison K.",
  },
  {
    text:
      "I love dealing with S&M as my management company. They are quick to address any issues in a timely manner and will keep you posted on when it will be resolved.",
    author: "Verena R.",
  },
  {
    text:
      "I have rented with S&M for the past 2 years, in two different buildings. They are the absolute best landlords I have ever worked with.",
    author: "Tara S.",
  },
]

const TRANSITION_TIMEOUT_MS = 10000

const Reviews = ({ reviews = REVIEWS }) => {
  const [currentReview, setCurrentReview] = useState(0)

  useEffect(() => {
    const handler = setTimeout(() => {
      const nextReview = (currentReview + 1) % reviews.length
      setCurrentReview(nextReview)
    }, TRANSITION_TIMEOUT_MS)
    return () => {
      clearTimeout(handler)
    }
  })

  return (
    <Box
      sx={{
        position: "relative",
        width: "100vw",
        height: "25vh",
        overflow: "hidden",
        margin: "0 auto",
      }}
    >
      {reviews.map((review, index) => (
        <Box
          key={index}
          id={`review-${index}`}
          sx={{
            position: "absolute",
            fontStyle: "italic",
            height: "100%",
            width: "100%",
            transition: "opacity .3s ease-in-out",
            opacity: currentReview === index ? 1 : 0,
            px: [3, 6],
            "@media(min-width: 1280px)": {
              px: 7,
            },
          }}
        >
          <Text>"{review.text}"</Text>
          <Text sx={{ mt: [3] }}>- {review.author}</Text>
        </Box>
      ))}
    </Box>
  )
}

export default Reviews
